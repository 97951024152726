import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { LoginComponent } from './core/components/login/login.component';
import { CallbackComponent } from './core/components/login/callback/callback.component';
import { DefaultPageComponent } from './features/default-page/default-page.component';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard],
       children: [
        { path: '', component: DefaultPageComponent },
        {
          path: 'schedule',
          loadChildren: () => import('./features/schedule/schedule.module').then(m => m.ScheduleModule)
        }
      ]
    },
    { path: 'login', component: LoginComponent },
    { path: 'callback', component: CallbackComponent },
    { path: '**', component: PageNotFoundComponent }
];

export const routing = RouterModule.forRoot(routes);
