import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramService } from '../education-program/services/program.service';
import { EducationProgram } from '../education-program/models/program';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss'],
  standalone: true,
  imports: [CommonModule, MatListModule, RouterModule]
})
export class DefaultPageComponent {
  dataSource: EducationProgram[] = [];

  constructor(private programService: ProgramService) { }

  ngOnInit(): void {
    this.getPrograms();
  }

  getPrograms(): void {
    this.programService.getPrograms().subscribe((data: EducationProgram[]) => {
      this.dataSource = data;
    });
  }
}
